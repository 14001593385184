export const environment = {
    e2e: false,
    production: false,
    firebase: {
        apiKey: "AIzaSyCD7LEQOwFXuSagphkC9erT4NdnK-OoXn4",
        authDomain: "cwl-216f549b602d-qa.firebaseapp.com",
        projectId: "cwl-216f549b602d-qa",
    },
    region: "europe-west1",
    brain_base_url: "https://dbg.api-test.deloitte.camp",
    aoh_url: "https://aoh.dbg.cwl.camp/",
    store_url: "https://dbg.cwl.camp/",
    api_base_url: "https://dbg.api-test.deloitte.camp",
    authParams: ["oidc.azure"],
    signInType: 'signInWithPopup',
    hasEnterpriseAssetSearch: false,
    asset_redirect_uri: {},
    upload: {
        allowUnclassifiedFilesUpload: true,
        confidentialMetadata: [],
        existingMetadata: []
    },
    onboarding: {
        displayVideo: false,
        video_url: "https://storage.googleapis.com/dcsp-catalog-assets-public/data/products/Deloitte%20AI%20Video/Deloitte%20AI%20-%20Asset%20Owner%20Hub.mp4",
        development: {
            frontend_url: "https://storage.googleapis.com/dcsp-catalog-assets-public/data/products/Frontend_Development_Kit/Deloitte.AI%20Frontend%20Development%20Kit.pdf",
            backend_url: "https://bitbucket.de.deloitte.com/projects/AI/repos/dcsp-python-framework/browse",
            business_support_email: "mailto:campfire@deloitte.de?subject=Question%20regarding%20Deloitte%20AI%20Marketplace",
        }
    },
    barrierQuestionsAbort: {
        mailAddress: "mahorst@deloitte.de"
    },
    support: {
        ccEmail: "",
        contact: "mailto:campfire@deloitte.de?subject=Question%20regarding%20Deloitte%20AI%20Marketplace"
    },
    onboarding_options: {
        "manual": {},
        "api_passthrough": {},
        "soft_onboarding": {}
    }
};
